import React, {Component} from 'react';
import Startupsitem from './StartupServiceItem';
import Reveal from 'react-reveal';

import laptop from '../../../assets/img/laptop.png';

class Stservice extends Component {
    render(){
        return(
            <section className="stratup_service_area sec_pad bg_color">
                <div className="container">
                    <div className="sec_title mb_70 wow fadeInUp" data-wow-delay="0.4s">
                       <Reveal effect="fadeInUp"><h2 className="f_p f_size_30 l_height40 f_600 t_color text-center">Giao diện thân thiện <br/> dễ dàng sử dụng</h2></Reveal>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="stratup_service_img">
                                <img className="laptop_img" src={laptop} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Stservice;