import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
    root: {},
    dialogPaper: {
        background: "#242526",
        minHeight: 250,
        width: "30vw",
        [theme.breakpoints.down("lg")]: {
            width: "40vw",
        },
        [theme.breakpoints.down("md")]: {
            width: "50vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "70vw",
        },
        [theme.breakpoints.down("xs")]: {
            width: "90vw",
        },
    },
    tabsHeader: {
        flexGrow: 1,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const WatingLobbyDialog = ({ open, classes }) => {

    return (
        <Dialog
            className={classes.root}
            open={open}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogContent>
                <div className="request-join-content">
                    <div className="request-join-content__spinner">
                        <CircularProgress />
                    </div>
                    <div className="request-join-content__title">
                        Đang yêu cầu tham gia...
                    </div>
                    <div className="request-join-content__description">
                        Bạn sẽ được tham gia sau khi được chủ phòng họp chấp nhận
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

WatingLobbyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WatingLobbyDialog);
