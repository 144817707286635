import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import JoinNav from './UI/Nav/JoinNav';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import queryString from "query-string";
import { Link } from '@material-ui/core';

const styles = (theme) =>
({
	root:
	{
		display: 'flex',
		width: '100%',
		height: 'calc(100vh - 64px)',
		backgroundAttachment: 'fixed',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		padding: 24,
		flexDirection: 'column',
		alignItems: 'center'
	},
	dialogTitle:
	{
	},
	joinDialog:
	{
		justifyContent: 'center',
		maxWidth: '100%'
	},
	title: {
		fontSize: 24,
		margin: '24px 0 10px',
		textAlign: 'center',
	},
	buttons: {
		padding: 10,
		alignSelf: 'flex-end',
		border: 'solid 0.5px white'
	}

});

const RoomEnded = (props) => {
	const {
		classes,
	} = props;
	let params = queryString.parse(props.location.search);
	const id = params.meetingID;
	const detailUrl = buildDetailUrl();

	function buildDetailUrl() {
		if (id) {
			return `${window.config.meetingManagementURL}/manage/meetings/${id}`;
		} else {
			return window.config.meetingManagementURL;
		}
	};

	return (
		<div className='join-page header'>
			<JoinNav />
			<div className={classes.root}>

				<Grid
					xs={6}
					item={true}
					container
					direction="row"
					direction="column"
					alignItems="center"
					className={classes.joinDialog}
				>
					<CheckCircleOutlineIcon style={{fontSize: 80}}/>
					<h1 className={classes.title}>
						<FormattedMessage
							id='index.roomEnded'
							defaultMessage='Cuộc hội thoại đã kết thúc'
						/>
					</h1>
					<Link href={detailUrl}>
						<FormattedMessage
							id='index.meetingDetail'
							defaultMessage='Chi tiết cuộc họp'
						/>
					</Link>
					<span style={{marginTop: 100}}>
						© Copyright 2021 – Hệ sinh thái Duca Việt Nam.
					</span>
				</Grid>

				<Grid>


				</Grid>
			</div>

		</div>
	);
};

RoomEnded.propTypes =
{
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RoomEnded);
