import React from 'react';
import CustomNavbar from './components/CustomNavbar';
import CrmBanner from './components/CrmBanner';
import StartupFeatures from './components/StartupFeatures';
import FooterData from './components/FooterData';
import Stservice from './components/StartupServices';
import FooterSecurity from './components/FooterSecurity';

import "../../assets/landing/themify-icon/themify-icons.css";
import "../../assets/landing/simple-line-icon/simple-line-icons.css";
import "../../assets/landing/animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/landing/main.css";
import "../../assets/landing/responsive.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js";

const LandingPage = () => {
    return(
        <div className="body_wrapper landing-page">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive"/>
            <CrmBanner/>
            <section className="chat_get_started_area" id="about">
                <div className="container">
                    <div className="chat_get_started_content text-center">
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">Cùng nhau khám phá Duca Meet</h2>
                        <p>Ứng dụng họp trực tuyến được “DUCA VIỆT NAM” thiết lập và vận hành. Thành viên trên Sàn Vnduca.com là các thương nhân, tổ chức, cá nhân có hoạt động thương mại hợp pháp được DUCA VIỆT NAM chính thức công nhận và được phép sử dụng dịch vụ do Sàn Vnduca.com và các bên liên quan cung cấp.</p>
                    </div>
                </div>
            </section>
            <StartupFeatures/>
            <Stservice/>
            <FooterSecurity FooterData={FooterData}/>
        </div>
    )
}
export default LandingPage;