import React, {Component} from 'react';
import Reveal from 'react-reveal';

import line_01 from '../../../assets/img/line_01.png';
import line_02 from '../../../assets/img/line_02.png';
import line_03 from '../../../assets/img/line_03.png';
import app_image_1 from '../../../assets/img/app_image_1.png';
import randomString from 'random-string';
import ch_play from '../../../assets/img/download_google_play.png';
import apple_store from '../../../assets/img/download_apple_store.png';



class CrmBanner extends Component {
    render(){
        return(
            <section className="new_startup_banner_area" id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.4s">
                            <Reveal left cascade>
                            <div className="new_startup_img">
                                <div className="line line_one"><img src={line_01} alt=""/></div>
                                <div className="line line_two"><img src={line_02} alt=""/></div>
                                <div className="line line_three"><img src={line_03} alt=""/></div>
                                <img src={app_image_1} alt=""/>
                            </div>
                            </Reveal>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Reveal bottom cascade duraton={1200}>
                                <div className="new_startup_content">
                                    <h2 className="f_700 f_size_40 l_height50 w_color mb_20 wow fadeInRight" data-wow-delay="0.3s">Với <span>Duca Meet</span>,<br/>giãn cách không còn là vấn đề nữa!</h2>
                                    <p className="f_400 w_color l_height28 wow fadeInRight" data-wow-delay="0.4s">Đội ngũ kỹ sư của chúng tôi đã xây dựng hệ thống này để phục vụ người Việt Nam có thể làm việc, họp mặt cùng nhau không giới hạn, đảm bảo về mặt chất lượng, bảo mật thông tin và thân thiện với mọi người.</p>
                                    <div className="action_btn d-flex align-items-center mt_40 wow fadeInRight" data-wow-delay="0.6s">
                                        <a href={'https://meet.duca.vn'} className="btn_hover app_btn create_meeting">Truy cập DUCA Meet <i className="ti-angle-right"></i></a>
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                        <div className="row download-mobile">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <Reveal bottom cascade>
                                    <div className="get_content">
                                        <a href=".#" className="download_app_btn wow fadeInLeft" data-wow-delay="0.5s"><img src={ch_play} alt=""/></a>
                                        <a href=".#" className="download_app_btn wow fadeInLeft" data-wow-delay="0.6s"><img src={apple_store} alt=""/></a>
                                    </div>
                                </Reveal>
                            </div>
                            <div className="col-12 d-flex align-items-center justify-content-center mt-5">
                                <div className="scroll-icon"></div>
                            </div>
                        </div>
                    </div>            
                </div>
            </section>
        )
    }
}
export default CrmBanner;