import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import JoinNav from './UI/Nav/JoinNav';
import IconButton from '@material-ui/core/IconButton';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const styles = (theme) =>
({
	root:
	{
		display: 'flex',
		width: '100%',
		height: '100vh',
		backgroundAttachment: 'fixed',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',

		flexDirection: 'column',
		alignItems: 'center'
	},
	dialogTitle:
	{
	},
	title: {
		fontSize: 24,
		margin: '10px 0 30px'
	},
	buttons: {
		padding: 10,
		alignSelf: 'flex-end',
		border: 'solid 0.5px white'
	}

});

const MeetingPermissionError = ({
	classes,
}) => {

	return (
		<div className='join-page header'>
			<JoinNav />
			<div className={classes.root}>

				<Grid
					xs={6}
					item={true}
					container
					direction="row"
					direction="column"
					alignItems="center"
					className={classes.joinDialog}
				>
					<IconButton
						className={classes.buttons}
						style={{ color: '#fffff', }}
						onClick={() => {
							window.close();
						}}
					>
						<CloseOutlinedIcon
							fontSize="medium"
						/>
					</IconButton>
					<h1 className={classes.title}>
						<FormattedMessage
							id='index.meetingPermissionError'
							defaultMessage='Quyền truy cập bị từ chối, vui lòng thử lại'
						/>
					</h1>
					<span >
						© Copyright 2021 – Hệ sinh thái Duca Việt Nam.
					</span>
				</Grid>

				<Grid>


				</Grid>
			</div>

		</div>
	);
};

MeetingPermissionError.propTypes =
{
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MeetingPermissionError);
