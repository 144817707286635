import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/logo.png';

class FooterSecurity extends Component {
  render() {
    let FooterData = this.props.FooterData;
    return (
      <footer className='footer_area footer_area h_footer_dark pos_footer_area security_footer_area'>
        <div className='container'>
          <div className='row'>
            {FooterData.CompanyWidget.map((widget) => {
              return (
                <Reveal effect='fadeInLeft' duration={500} key={1}>
                  <div className='col-lg-4 col-sm-6'>
                    <div
                      className='f_widget dark_widget company_widget'
                      data-wow-delay='0.2s'
                    >
                      <a href='index.html' className='f-logo'>
                        <img src={logo} alt='' />
                      </a>
                      <p>
                        ©2021 Duca. Đã đăng ký bản quyền
                      </p>
                      <div className='f_social_icon'>
                        {FooterData.socialIcon.map((item) => {
                          return (
                            <a href='/' key={item.id}>
                              <i className={item.icon}></i>
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Reveal>
              );
            })}
            {FooterData.AboutWidget.map((widget) => {
              return (
                <Reveal effect='fadeInLeft' duration={500} key={widget.id}>
                  <div className='col-lg-3 col-sm-6'>
                    <div className='f_widget dark_widget about-widget pl_70'>
                      <h3 className='f-title f_500 t_color f_size_18 mb_40'>
                        {widget.title}
                      </h3>
                      <ul className='list-unstyled f_list'>
                        {widget.menuItems.map((item) => {
                          return (
                            <li key={item.id}>
                              <Link to='/'>{item.text}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Reveal>
              );
            })}
            {FooterData.termsCondition.map((widget) => {
              return (
                <Reveal effect='fadeInLeft' duration={500} key={widget.id}>
                  <div className='col-lg-2 col-sm-6'>
                    <div className='f_widget dark_widget about-widget'>
                      <h3 className='f-title f_500 t_color f_size_18 mb_40'>
                        {widget.title}
                      </h3>
                      <ul className='list-unstyled f_list'>
                        {widget.menuItems.map((item) => {
                          return (
                            <li key={item.id}>
                              <Link to='/'>{item.text}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Reveal>
              );
            })}
          </div>
        </div>
        <div className='company-info-container'>
          <div className='company-info'>
            CÔNG TY CỔ PHẦN PHÁT TRIỂN ỨNG DỤNG DI ĐỘNG SÁNG TẠO HỮU ÍCH<br/>
            Người chịu trách nhiệm chính: Chu Mạnh Long<br/>
            Số điện thoại liên hệ: 0961 538 989 Địa chỉ, email: info@ducavietnam.vn<br/>
            Địa chỉ trụ sở chính: Số 38 Đường La Thành, P. Ô Chợ Dừa, Q. Đống Đa, Tp. Hà Nội, Việt Nam<br/>
            Mã số thuế: 0106023416 Ngày cấp: 31/10/2012, nơi cấp: Sở kế hoạch đầu tư TP. Hà Nội<br/>
          </div>
        </div>
        <svg className='wave_shap' xmlns='http://www.w3.org/2000/svg'>
          <path
            opacity='0.302'
            fill='#178393'
            d='M22.990,11.999 C22.990,11.999 -62.334,196.188 102.000,90.998 C236.033,5.204 349.217,18.152 512.000,80.998 C773.380,181.909 898.075,49.752 1051.000,15.998 C1316.369,-42.574 1488.518,78.268 1581.000,112.999 C1783.485,189.036 1942.990,61.999 1942.990,61.999 L1942.990,211.999 L22.990,211.999 L22.990,11.999 Z'
          />
        </svg>
      </footer>
    );
  }
}
export default FooterSecurity;
