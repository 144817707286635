import React, { useState, useEffect } from 'react';
import logoInRom from '../../../assets/img/logo-in-room.png';

const JoinNav = () => {
  const [navClass, setNavClass] = useState('');
  const [toggeledNav, settoggeledNav] = useState(false);

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let navClass = '';
      if (window.scrollY >= 200) {
        navClass = 'scrolled';
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <nav className={`navbar navbar-expand-md ${navClass}`}>
      <div className='container'>
        <a href="/">
          <img alt='Logo' src={logoInRom} style={{height: 32, margin: '8px 0'}}/>
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return 'open';
            return '';
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    </nav>
  );
}

export default JoinNav;