import React, { useEffect, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoadingView from "./LoadingView";
import RoomEnded from "./RoomEnded";
import { ReactLazyPreload } from "./ReactLazyPreload";
import queryString from "query-string";
import * as settingsActions from '../actions/settingsActions';
import { withRoomContext } from '../RoomContext';

const DirectCallRoom = ReactLazyPreload(() =>
  import(/* webpackChunkName: "directcallroom" */ "./DirectCallRoom")
);

const DirectCall = (props) => {
  const {
    roomClient,
    room,
    mediaPerms,
    setAudioMuted,
    setVideoMuted } = props;
  let params = queryString.parse(props.location.search);
  const meetingID = params.meetingID;
  const sessionID = params.sessionID;
  const channelType = params.channelType;
  useEffect(() => {
    DirectCallRoom.preload();

    handleJoin();

    return;
  }, []);



  const handleJoin = () => {

    setAudioMuted(false);

    let isVideoChannel = channelType == 'MeetingVideoChannel';
    setVideoMuted(!isVideoChannel);

    if (mediaPerms.video || mediaPerms.audio) {
      navigator.mediaDevices.getUserMedia(mediaPerms).then(stream => {
        roomClient.join({
          roomId: meetingID,
          sessionId: sessionID,
          joinVideo: mediaPerms.video,
          joinAudio: mediaPerms.audio,
        });
      }, err => {
        console.log(err);
      });
    } else {
      roomClient.join({
        roomId: meetingID,
        sessionId: sessionID,
        joinVideo: mediaPerms.video,
        joinAudio: mediaPerms.audio,
      });
    }
  };


  if (!room.joined) {
    return <LoadingView />;
  } else {
    if (room.ended) {
      return <RoomEnded />;
    }
    return (
      <Suspense fallback={<LoadingView />}>
        <DirectCallRoom />
      </Suspense>
    );
  }
};

DirectCall.propTypes = {
  roomClient: PropTypes.any.isRequired,
  room: PropTypes.object.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
  mediaPerms: PropTypes.object.isRequired,
  setAudioMuted: PropTypes.any.isRequired,
  setVideoMuted: PropTypes.any.isRequired
};

const mapStateToProps = (state) => ({
  room: state.room,
  mediaPerms: state.settings.mediaPerms,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeDisplayName: (displayName) => {
      dispatch(settingsActions.setDisplayName(displayName));
    },
    setAudioMuted: (flag) => {
      dispatch(settingsActions.setAudioMuted(flag));
    },
    setVideoMuted: (flag) => {
      dispatch(settingsActions.setVideoMuted(flag));
    },
  };
};
export default withRoomContext(connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return prev.room === next.room && prev.room.ended === prev.room.ended;
  },
})(DirectCall));
