import React, {Component} from 'react'
import Sticky from 'react-stickynode';
import {Link} from 'react-scroll';
import logoLanding from '../../../assets/img/logo-landing.png';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <a href="/" className={`navbar-brand ${slogo}`}>
                            <img src={logoLanding} alt="logo"/>
                        </a>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ${nClass}`}>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="home" spy={true} smooth={true} offset={0} duration={500}>Trang chủ</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="about" spy={true} smooth={true} offset={0} duration={1000}>Giới thiệu</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="features" spy={true} smooth={true} offset={0} duration={1000}>Tính năng</Link>
                                </li>
                            </ul>
                            <div className="header-actions">
                                <span><i className="ti-mobile"></i>1800 888855</span>
                                <span><i className="ti-email"></i>info@ducavietnam.vn</span>
                                <a href={'https://meet.duca.vn'} className="btn_hover app_btn login-btn">Đăng nhập</a>
                                <a href={'https://meet.duca.vn/signup-oidc'} className="btn_hover app_btn register-btn">Đăng kí</a>
                            </div>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;