import React, { useEffect, Suspense, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingView from './LoadingView';
import JoinLanding from './JoinLanding';
import { ReactLazyPreload } from './ReactLazyPreload';
import queryString from 'query-string';
import * as settingsActions from '../actions/settingsActions';
import { withRoomContext } from '../RoomContext';

const Room = ReactLazyPreload(() =>
  import(/* webpackChunkName: "room" */ './Room')
);

const Conference = (props) => {
  const { roomClient, room, mediaPerms, setAudioMuted, setVideoMuted } = props;
  let params = queryString.parse(props.location.search);
  const meetingID = params.meetingID;
  const sessionID = params.sessionID;
  const channelType = params.channelType;
  const defaultEnableVideo = channelType == 'MeetingVideoChannel';


  useEffect(() => {
    Room.preload();
    setAudioMuted(false);
    setVideoMuted(!defaultEnableVideo);
    _askForPerms();
  }, []);

  const _askForPerms = () => {
    if (mediaPerms.video || mediaPerms.audio) {
      navigator.mediaDevices.getUserMedia(mediaPerms);
    }
  };

  const handleJoin = (enableMicro, enableVideo) => {
    setAudioMuted(!enableMicro);
    setVideoMuted(!enableVideo);

    roomClient.join({
      roomId: meetingID,
      sessionId: sessionID,
      joinVideo: enableVideo,
      joinAudio: enableMicro,
    });
  };

  function onClickJoin(enableMicro, enableVideo) {
    handleJoin(enableMicro, enableVideo);
  }

  if (!room.joined)
	{
		return (
      <JoinLanding
        defaultEnableVideo={defaultEnableVideo}
        defaultEnableMicro={true}
        onClickJoin={onClickJoin}
      />
		);
	}
	else
	{
		return (
			<Suspense fallback={<LoadingView />}>
        <Room />
      </Suspense>
		);
	}
};

Conference.propTypes = {
  roomClient: PropTypes.any.isRequired,
  room: PropTypes.object.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
  mediaPerms: PropTypes.object.isRequired,
  setAudioMuted: PropTypes.any.isRequired,
  setVideoMuted: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  room: state.room,
  mediaPerms: state.settings.mediaPerms,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeDisplayName: (displayName) => {
      dispatch(settingsActions.setDisplayName(displayName));
    },
    setAudioMuted: (flag) => {
      dispatch(settingsActions.setAudioMuted(flag));
    },
    setVideoMuted: (flag) => {
      dispatch(settingsActions.setVideoMuted(flag));
    },
  };
};
export default withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => {
      return prev.room === next.room;
    },
  })(Conference)
);
