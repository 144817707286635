import React, { useEffect, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoadingView from "./LoadingView";
import RoomEnded from "./RoomEnded";
import { ReactLazyPreload } from "./ReactLazyPreload";
import queryString from "query-string";
import * as settingsActions from '../actions/settingsActions';
import JoinLanding from './JoinLanding';
import { withRoomContext } from '../RoomContext';

const LiveStreamRoom = ReactLazyPreload(() =>
  import(/* webpackChunkName: "directcallroom" */ "./LiveStreamRoom")
);

const LiveStream = (props) => {
  const {
    roomClient,
    room,
    mediaPerms,
    setAudioMuted,
    setVideoMuted } = props;
  let params = queryString.parse(props.location.search);
  const meetingID = params.meetingID;
  const sessionID = params.sessionID;
  const channelType = params.channelType;
  const defaultEnableVideo = channelType == 'MeetingVideoChannel';

  useEffect(() => {
    LiveStreamRoom.preload();
    setAudioMuted(false);
    setVideoMuted(!defaultEnableVideo);
    _askForPerms();
    handleJoin();
    return;
  }, []);

  const _askForPerms = () => {
    if (mediaPerms.video || mediaPerms.audio) {
      navigator.mediaDevices.getUserMedia(mediaPerms);
    }
  };

  const handleJoin = async () => {
    setAudioMuted(false);
    setVideoMuted(false);

    await roomClient.join({
      roomId: meetingID,
      sessionId: sessionID,
      joinVideo: true,
      joinAudio: true,
    });
  };

  if (!room.joined)
	{
		return <LoadingView />;
	}
	else
	{
		return (
			<Suspense fallback={<LoadingView />}>
        <LiveStreamRoom />
      </Suspense>
		);
	}
};

LiveStream.propTypes = {
  roomClient: PropTypes.any.isRequired,
  room: PropTypes.object.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
  mediaPerms: PropTypes.object.isRequired,
  setAudioMuted: PropTypes.any.isRequired,
  setVideoMuted: PropTypes.any.isRequired
};

const mapStateToProps = (state) => ({
  room: state.room,
  mediaPerms: state.settings.mediaPerms,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeDisplayName: (displayName) => {
      dispatch(settingsActions.setDisplayName(displayName));
    },
    setAudioMuted: (flag) => {
      dispatch(settingsActions.setAudioMuted(flag));
    },
    setVideoMuted: (flag) => {
      dispatch(settingsActions.setVideoMuted(flag));
    },
  };
};
export default withRoomContext(connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return prev.room === next.room && prev.room.ended === prev.room.ended;
  },
})(LiveStream));
