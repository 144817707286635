import React, { useEffect, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoadingView from "./LoadingView";
import { ReactLazyPreload } from "./ReactLazyPreload";
import queryString from "query-string";
import * as settingsActions from '../actions/settingsActions';
import * as roomActions from '../actions/roomActions';
import { withRoomContext } from '../RoomContext';

const BotRecordingCallRoom = ReactLazyPreload(() =>
  import(/* webpackChunkName: "botrecordingcallroom" */ "./BotRecordingCallRoom")
);

const BotRecordingCall = (props) => {
  const {
    roomClient,
    room,
    setAudioMuted,
    setVideoMuted,
    setHideSelfView } = props;
  let params = queryString.parse(props.location.search);
  const meetingID = params.meetingID;
  const sessionID = params.sessionID;
  useEffect(() => {
    BotRecordingCallRoom.preload();

    handleJoin();

    return;
  }, []);

  const handleJoin = () => {
    setAudioMuted(true);
    setVideoMuted(true);
    setHideSelfView(true);
    roomClient.join({
      roomId: meetingID,
      sessionId: sessionID,
      joinVideo: false,
      joinAudio: false,
    });
  };

  if (!room.joined) {
    return <LoadingView />;
  } else {
    return (
      <Suspense fallback={<LoadingView />}>
        <BotRecordingCallRoom />
      </Suspense>
    );
  }
};

BotRecordingCall.propTypes = {
  roomClient: PropTypes.any.isRequired,
  room: PropTypes.object.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
  setAudioMuted: PropTypes.any.isRequired,
  setVideoMuted: PropTypes.any.isRequired,
  setHideSelfView: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  room: state.room,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeDisplayName: (displayName) => {
      dispatch(settingsActions.setDisplayName(displayName));
    },
    setAudioMuted: (flag) => {
      dispatch(settingsActions.setAudioMuted(flag));
    },
    setVideoMuted: (flag) => {
      dispatch(settingsActions.setVideoMuted(flag));
    },
    setHideSelfView: (hideSelfView) => {
      dispatch(roomActions.setHideSelfView(hideSelfView));
    },
  };
};
export default withRoomContext(connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return prev.room === next.room;
  },
})(BotRecordingCall));
