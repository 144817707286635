export function getSignalingUrl(peerId, roomId, sessionId) {
	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;
	const sfuHost = process.env.NODE_ENV !== 'production' ?
		`ws://${window.config.developmentSfuHost}:${port}`
		:
		`wss://${window.config.productionSfuHost}:${port}`;

	const url = `${sfuHost}/?peerId=${peerId}&roomId=${roomId}&sessionId=${sessionId}`;

	return url;
}
