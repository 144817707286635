import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRoomContext } from '../RoomContext';
import { connect } from 'react-redux';
import * as settingsActions from '../actions/settingsActions';
import logoInRom from '../assets/img/logo-in-room.png';
import Webcam from 'react-webcam';
import VideoOffIcon from '@material-ui/icons/VideocamOff';
import VideoIcon from '@material-ui/icons/Videocam';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Settings from './Settings/Settings';
import WaitingLobbyDialog from './WaitingLobbyDialog/WaitingLobbyDialog';

import * as roomActions from '../actions/roomActions';

const videoConstraints = {
  width: 528,
  height: 297,
  facingMode: 'user',
};

const JoinLanding = (props) => {
  const {
    defaultEnableMicro,
    defaultEnableVideo,
    roomClient,
    room,
    mediaPerms,
    setAudioMuted,
    setVideoMuted,
    setSettingsOpen,
    onClickJoin
  } = props;
  const webcamRef = React.useRef(null);

  const [enableMicro, setEnableMicro] = useState(defaultEnableMicro);
  const [enableVideo, setEnableVideo] = useState(defaultEnableVideo);

  useEffect(() => {
    return;
  }, []);
  
  function onClickBack() {
    window.location = window.config.meetingManagementURL;
  }

  return (
    <div className='join-landing'>
      <div className='join-landing-header'>
        <img alt='Logo' className='join-landing-header__logo' src={logoInRom} />
        <a className='join-landing-header__link' href={window.config.meetingManagementURL}/>
      </div>
      <div className='join-landing-content'>
        <div className='join-landing-title'>Tham gia phòng họp</div>
        <div className='join-landing-description'>
          Kiểm tra thiết bị của bạn trước khi tham gia cuộc họp
        </div>
        <div className='join-landing-preview-container'>
          <div className='video-preview-wrapper'>
            {enableVideo && (
              <Webcam
                className='video-preview'
                audio={false}
                ref={webcamRef}
                videoConstraints={videoConstraints}
              />
            )}
            {!enableVideo && (
              <div className='video-preview-disabled-label'>
                Máy ảnh đang tắt
              </div>
            )}
            {!enableMicro && (
              <div className='audio-preview-disabled-label'>
                <MicOffIcon style={{ fontSize: 20 }} /> Micro đang tắt
              </div>
            )}
          </div>
          <div className='video-preview-actions'>
            <Tooltip title={'Bật/tắt máy ảnh'} placement='top'>
              <IconButton
                color={enableVideo ? 'primary' : 'secondary'}
                size='small'
                onClick={() => setEnableVideo(!enableVideo)}
              >
                {enableVideo ? <VideoIcon /> : <VideoOffIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={'Bật/tắt micro'} placement='top'>
              <IconButton
                color={enableMicro ? 'primary' : 'secondary'}
                size='small'
                onClick={() => setEnableMicro(!enableMicro)}
              >
                {enableMicro ? <MicIcon /> : <MicOffIcon />}
              </IconButton>
            </Tooltip>
            <div style={{ width: '100%' }}></div>
            <Tooltip title={'Cài đặt thiết bị'} placement='top'>
              <IconButton
                color='primary'
                size='small'
                style={{ marginRight: 0 }}
                onClick={() => setSettingsOpen(true)}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='join-landing-footer'>
        <div className='join-button-container'>
          <Button className='join-button-container__back-btn' variant='contained' color='default'  onClick={() => onClickBack()}>
            Quay về
          </Button>
          <div style={{width: '100%'}}></div>
          <span className='join-button-container__ready-label'>
            Đã sẵn sàng?
          </span>
          <Button className='join-button-container__join-btn' variant='contained' color='primary' onClick={() => onClickJoin(enableMicro, enableVideo)}>
            Tham gia
          </Button>
        </div>
      </div>
      {room.inLobby &&
        <WaitingLobbyDialog open={room.inLobby}/>
      }
      {room.settingsOpen && <Settings />}
    </div>
  );
};

JoinLanding.propTypes = {
  defaultEnableVideo: PropTypes.bool.isRequired,
  defaultEnableMicro: PropTypes.bool.isRequired,
  roomClient: PropTypes.any.isRequired,
  room: PropTypes.object.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
  mediaPerms: PropTypes.object.isRequired,
  setAudioMuted: PropTypes.any.isRequired,
  setVideoMuted: PropTypes.any.isRequired,
  setSettingsOpen: PropTypes.func.isRequired,
  onClickJoin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  room: state.room,
  mediaPerms: state.settings.mediaPerms,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeDisplayName: (displayName) => {
      dispatch(settingsActions.setDisplayName(displayName));
    },
    setAudioMuted: (flag) => {
      dispatch(settingsActions.setAudioMuted(flag));
    },
    setVideoMuted: (flag) => {
      dispatch(settingsActions.setVideoMuted(flag));
    },
    setSettingsOpen: (flag) => {
      dispatch(roomActions.setSettingsOpen(flag));
    },
  };
};
export default withRoomContext(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatesEqual: (next, prev) => {
      return prev.room === next.room;
    },
  })(JoinLanding)
);
