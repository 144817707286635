import React, {Component} from 'react';
import Reveal from 'react-reveal';
import web_image from '../../../assets/img/web_image.png';
import feature_education from '../../../assets/img/feature_education.png';
import feature_share_screen from '../../../assets/img/feature_share_screen.png';
import feature_chat from '../../../assets/img/feature_chat.png';
import feature_share_file from '../../../assets/img/feature_share_file.png';
import iphoneX from '../../../assets/img/iPhoneX.png';

class StartupFeatures extends Component {
    render(){
        return(
            <section className="startup_fuatures_area sec_pad" id="features">
                <div className="container">
                    <div className="sec_title mb_70 wow fadeInUp" data-wow-delay="0.4s">
                    <Reveal effect="fadeInLeft"><h2 className="f_p f_size_30 l_height40 f_600 t_color text-center">Tính năng</h2></Reveal>
                    </div>
                    <ul className="nav nav-tabs startup_tab" id="myTab" role="tablist">
                        <Reveal effect="fadeInLeft">
                            <li className="nav-item">
                            <a className="nav-link active" id="market-tab" data-toggle="tab" href="#market" role="tab" aria-controls="market" aria-selected="true">
                                <span className="icon"><i className="ti-video-camera"></i></span>
                                <h3>Họp<br/> trực tuyến</h3>
                            </a>
                            </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={1400}>
                            <li className="nav-item">
                                <a className="nav-link" id="app-tab" data-toggle="tab" href="#app" role="tab" aria-controls="app" aria-selected="false">
                                    <span className="icon"><i className="icon-graduation"></i></span>
                                    <h3>Đào tạo</h3>
                                </a>
                            </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={2000}>
                            <li className="nav-item">
                                <a className="nav-link" id="hubstaff-tab" data-toggle="tab" href="#hubstaff" role="tab" aria-controls="hubstaff" aria-selected="false">
                                    <span className="icon"><i className="icon-screen-desktop"></i></span>
                                    <h3>Chia sẻ<br/> màn hình</h3>
                                </a>
                            </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={2400}>
                        <li className="nav-item">
                            <a className="nav-link" id="engine-tab" data-toggle="tab" href="#engine" role="tab" aria-controls="engine" aria-selected="false">
                                <span className="icon"><i className="icon-bubble"></i></span>
                                <h3>Chat</h3>
                            </a>
                        </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={2800}>
                        <li className="nav-item">
                            <a className="nav-link" id="business-tab" data-toggle="tab" href="#business" role="tab" aria-controls="business" aria-selected="false">
                                <span className="icon"><i className="icon-paper-clip"></i></span>
                                <h3>Chia sẻ file<br/></h3>
                            </a>
                        </li>
                        </Reveal>
                    </ul>
                    <div className="tab-content startup_tab_content" id="myTabContent">
                        <div className="tab-pane fade show active" id="market" role="tabpanel" aria-labelledby="market-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={web_image} alt=""/>
                                </div>
                                <div className="phone_img"><img src={iphoneX} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="app" role="tabpanel" aria-labelledby="app-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={feature_education} alt="" style={{width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="hubstaff" role="tabpanel" aria-labelledby="hubstaff-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={feature_share_screen} alt="" style={{width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="engine" role="tabpanel" aria-labelledby="engine-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={feature_chat} alt="" style={{width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={feature_share_file} alt="" style={{width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default StartupFeatures;