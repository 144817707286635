import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../RoomContext';
import * as settingsActions from '../actions/settingsActions';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import randomString from 'random-string';
import { useHistory, useLocation } from 'react-router-dom';
import JoinNav from './UI/Nav/JoinNav'

const styles = (theme) =>
({
	root:
	{
		display: 'flex',
		width: '100%',
		height: '100vh',
		backgroundAttachment: 'fixed',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',

		flexDirection: 'column',
		alignItems: 'center'
	},
	dialogTitle:
	{
	},
	title: {
		fontSize: 24,
		margin: '10px 0 30px'
	},
	dialogPaper:
	{
		width: '30vw',
		padding: theme.spacing(2),
		[theme.breakpoints.down('lg')]:
		{
			width: '40vw'
		},
		[theme.breakpoints.down('md')]:
		{
			width: '50vw'
		},
		[theme.breakpoints.down('sm')]:
		{
			width: '70vw'
		},
		[theme.breakpoints.down('xs')]:
		{
			width: '90vw'
		}
	},
	accountButton:
	{
		padding: 0
	},
	accountButtonAvatar:
	{
		width: 50,
		height: 50
	},
	green:
	{
		color: '#5F9B2D'
	},
	red:
	{
		color: 'rgba(153, 0, 0, 1)'
	},
	joinButton:
	{
		background: 'red',
		color: 'white',
		'&:hover': {
			backgroundColor: '#2e7031'
		},
		backgroundColor: '#FD8126'
	},
	joinDialog:
	{
		marginTop: 90,
		minHeight: '70vh'
	},
	footerText:
	{
		textAlign: 'center',
		marginTop: 24,
		background: 'white',
		color: '#666',
		fontSize: 14,
		fontWight: 300
	},
	mediaDevicesAnySelectedButton:
	{
		'& .Mui-selected': {
			color: 'white',
			backgroundColor: '#5F9B2D',
			'&:hover': {
				color: 'white',
				backgroundColor: '#5F9B2D'
			}
		}

	},

	mediaDevicesNoneSelectedButton:
	{
		'& .Mui-selected': {
			color: 'white',
			backgroundColor: '#FF6262',
			'&:hover': {
				color: 'white',
				backgroundColor: '#FF6262'
			}
		}

	}

});

const DialogTitle = withStyles((theme) => ({
	root:
	{
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
	root:
	{
		padding: theme.spacing(2),
		paddingTop: theme.spacing(1)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root:
	{
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const JoinDialog = ({
	roomClient,
	room,
	mediaPerms,
	displayName,
	displayNameInProgress,
	loggedIn,
	myPicture,
	changeDisplayName,
	setMediaPerms,
	classes,
	setAudioMuted,
	setVideoMuted
}) => {

	const location = useLocation();

	const history = useHistory();

	const intl = useIntl();

	displayName = displayName.trimLeft();

	const authTypeDefault = (loggedIn) ? 'auth' : 'guest';

	const [authType, setAuthType] = useState(authTypeDefault);

	const [roomId, setRoomId] = useState(
		decodeURIComponent(location.pathname.slice(1)) ||
		randomString({ length: 8 }).toLowerCase()
	);

	useEffect(() => {
		window.history.replaceState({}, null, encodeURIComponent(roomId) || '/');

	}, [roomId]);

	useEffect(() => {
		(location.pathname === '/') && history.push(encodeURIComponent(roomId));
	});

	const _askForPerms = () => {
		if (mediaPerms.video || mediaPerms.audio) {
			navigator.mediaDevices.getUserMedia(mediaPerms);
		}
	};

	const handleSetMediaPerms = (event, newMediaPerms) => {

		if (newMediaPerms !== null) {
			setMediaPerms(JSON.parse(newMediaPerms));
		}
	};

	const handleSetAuthType = (event, newAuthType) => {
		if (newAuthType !== null) {
			setAuthType(newAuthType);
		}

	};

	const handleJoin = () => {
		setAudioMuted(false);

		setVideoMuted(false);

		_askForPerms();

		const encodedRoomId = encodeURIComponent(roomId);

		roomClient.join({
			roomId: encodedRoomId,
			joinVideo: mediaPerms.video,
			joinAudio: mediaPerms.audio
		});
	};

	const handleFocus = (event) => event.target.select();

	/*
	const handleAuth = () =>
	{
		_askForPerms();

		const encodedRoomId = encodeURIComponent(roomId);

		!loggedIn ?
			roomClient.login(encodedRoomId) :
			roomClient.join({
				roomId    : encodedRoomId,
				joinVideo : mediaPerms.video,
				joinAudio : mediaPerms.audio
			});

	};
	*/

	const handleJoinUsingEnterKey = (event) => {
		if (event.key === 'Enter') document.getElementById('joinButton').click();
	};

	const handleChangeDisplayName = (event) => {
		const { key } = event;

		switch (key) {
			case 'Enter':
			case 'Escape':

				{
					displayName = displayName.trim();

					if (displayName === '')
						changeDisplayName(
							`Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`);
					if (room.inLobby)
						roomClient.changeDisplayName(displayName);
					break;
				}
			default:
				break;
		}
	};

	return (
		<div className='join-page header'>
			<JoinNav />
			<div className={classes.root}>
				<Grid
					xs={12}
					item={true}
					container
					direction="row"
					direction="column"
					alignItems="center"
					className={classes.joinDialog}
				>
					<h1 className={classes.title}>
						<FormattedMessage
							id='index.joinOrCreateAMeeting'
							defaultMessage='Tham gia hoặc tạo phòng họp'
						/>
						
					</h1>
					<TextField
						autoFocus
						id='roomId'
						label="Mã phòng"
						value={roomId}
						variant='outlined'
						margin='normal'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<MeetingRoomIcon />
								</InputAdornment>
							)
						}}
						onChange={(event) => {
							const { value } = event.target;

							setRoomId(value.toLowerCase());

						}}
						onFocus={handleFocus}
						onBlur={() => {
							if (roomId === '')
								setRoomId(randomString({ length: 8 }).toLowerCase());
						}}
						fullWidth
					/>
					<TextField
						id='displayname'
						label="Tên của bạn"
						value={displayName}
						variant='outlined'
						onFocus={handleFocus}

						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<AccountCircle />
								</InputAdornment>
							)
						}}

						margin='normal'
						disabled={displayNameInProgress}
						onChange={(event) => {
							const { value } = event.target;

							changeDisplayName(value);
						}}
						onKeyDown={handleChangeDisplayName}
						onBlur={() => {
							displayName = displayName.trim();

							if (displayName === '')
								changeDisplayName(`Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`);
							if (room.inLobby)
								roomClient.changeDisplayName(displayName);
						}}
						fullWidth
					/>
					<Button
						onClick={handleJoin}
						variant='contained'
						color='primary'
						id='joinButton'
						size='large'
						fullWidth
						margin='normal'
					>
						<FormattedMessage
							id='label.join'
							defaultMessage='Tham gia'
						/>
					</Button>

				</Grid>

				<Grid>
					<span className={classes.footerText}>
						© Copyright 2021 – Hệ sinh thái Duca Việt Nam.
					</span>

				</Grid>
			</div>

		</div>
	);
};

JoinDialog.propTypes =
{
	roomClient: PropTypes.any.isRequired,
	room: PropTypes.object.isRequired,
	roomId: PropTypes.string.isRequired,
	displayName: PropTypes.string.isRequired,
	displayNameInProgress: PropTypes.bool.isRequired,
	loginEnabled: PropTypes.bool.isRequired,
	loggedIn: PropTypes.bool.isRequired,
	myPicture: PropTypes.string,
	changeDisplayName: PropTypes.func.isRequired,
	setMediaPerms: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	mediaPerms: PropTypes.object.isRequired,
	setAudioMuted: PropTypes.any.isRequired,
	setVideoMuted: PropTypes.any.isRequired
};

const mapStateToProps = (state) => {
	return {
		room: state.room,
		mediaPerms: state.settings.mediaPerms,
		displayName: state.settings.displayName,
		displayNameInProgress: state.me.displayNameInProgress,
		loginEnabled: state.me.loginEnabled,
		loggedIn: state.me.loggedIn,
		myPicture: state.me.picture
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeDisplayName: (displayName) => {
			dispatch(settingsActions.setDisplayName(displayName));
		},

		setMediaPerms: (mediaPerms) => {
			dispatch(settingsActions.setMediaPerms(mediaPerms));
		},
		setAudioMuted: (flag) => {
			dispatch(settingsActions.setAudioMuted(flag));
		},
		setVideoMuted: (flag) => {
			dispatch(settingsActions.setVideoMuted(flag));
		}

	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual: (next, prev) => {
			return (
				prev.room.inLobby === next.room.inLobby &&
				prev.room.signInRequired === next.room.signInRequired &&
				prev.room.overRoomLimit === next.room.overRoomLimit &&
				prev.settings.displayName === next.settings.displayName &&
				prev.settings === next.settings &&
				prev.me.displayNameInProgress === next.me.displayNameInProgress &&
				prev.me.loginEnabled === next.me.loginEnabled &&
				prev.me.loggedIn === next.me.loggedIn &&
				prev.me.picture === next.me.picture
			);
		}
	}
)(withStyles(styles)(JoinDialog)));
